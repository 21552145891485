import React, { useEffect } from 'react'
import styled from 'styled-components'
import App from 'App'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { H1, H3, Text } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import bgImageSocialMedias from 'static/images/home/bg_social_medias.webp'

import logoBig from 'static/svg/logo_big.svg'

import imageFeatureConnect from 'static/images/reelies/connect.svg'
import imageFeatureCalendar from 'static/images/reelies/calendar.svg'
import imageFeatureTeam from 'static/images/reelies/team.svg'
import imageFeatureAccess from 'static/images/reelies/access.svg'

import bgNotSure from 'static/images/reelies/bg_not_sure.svg'

import bgInstagramHooks from 'static/images/reelies/bg_instagram_hooks.webp'
import imageInstagramHooks from 'static/images/reelies/image_instagram_hooks.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const BgImgBackground = styled(LocalImage)`
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ borderRadius }) => borderRadius || 'unset'};
`

const StyledGetStartedButtonWrapper = styled(Flex)`
  box-shadow: 0px 18px 24px -8px rgb(36 130 253 / 32%);
`

const StyledButtonGradient = styled(Button.Gradient)`
  height: 100%;
`

const StyledDemoLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

const StyledFeaturesWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.xxxl};
  background: radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063e3 36.77%, #0250c9 100%);
  padding: ${space.l};
`

const StyledFormWrapper = styled(Flex)`
  background: ${COLOR_CONSTANTS.BABY_BLUE_EYES};
  border-radius: ${radius.xxl};
  padding: ${space.m};
`

const FEATURE_ITEMS = [
  {
    title: 'Connect your client profiles in minutes',
    description: 'Get your client’s profiles connected or give your client the ability to do that.',
    icon: imageFeatureConnect,
  },
  {
    title: 'Load up their content calendar',
    description: 'Schedule a ton of content for them quickly for an immediate impact.',
    icon: imageFeatureCalendar,
  },
  {
    title: 'Assign team permissions',
    description: 'Quickly assign appropriate team members access and permissions.',
    icon: imageFeatureTeam,
  },
  {
    title: 'Enable client access to their account',
    description: 'Should your client be interested, give them access as well! They will only see their own data!',
    icon: imageFeatureAccess,
  },
]

const PROMO_LINK = 'https://vistasocial.com/promo/Reelies'
const DEMO_LINK = 'https://vistasocial.com/demo/?utm_source=reelies+LP&utm_medium=event+promo&utm_campaign=reelies+event'

const ReeliesLanding = () => {
  const addExternalScript = (url, callback) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true

    if (callback) {
      script.onload = callback
    }

    document.body.appendChild(script)
  }

  useEffect(() => {
    addExternalScript('//js.hsforms.net/forms/embed/v2.js', () => {
      addExternalScript('/reelies-landing/hbspt_script.js')
    })

    if (process.browser) {
      const getButtonElements = document.getElementsByClassName('header-login-button')

      if (getButtonElements && getButtonElements.length !== 0) {
        for (let i = 0; i < getButtonElements.length; i++) {
          getButtonElements[i].href = PROMO_LINK
        }
      }
    }
  }, [])

  return (
    <App fullHeader>
      <SEO
        title="Goodbye Average SMM Tools. 👋Vista Social: Feature Heavy, Future Ready."
        description="From bulk scheduling, boosting and highly customized reporting to endless integrations and employee advocacy; we take social media management to a whole new level. Seriously."
        path="/reelies/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb={{ mobile: 'l', desktop: 'xl' }} flexDirection="column" alignItems="center">
            <BgImgBackground
              src={bgImageSocialMedias}
              width="auto"
              objectFit={{ mobile: 'contain', tablet: 'cover' }}
              height="100%"
              display={{ mobile: 'none', tablet: 'block' }}
            />
            <Flex pt={{ mobile: 'l', tablet: 'xxl' }} alignItems="center" flexDirection="column" position="relative">
              <H1 fontSize="4xl" fontWeight="bold" textAlign="center" color={COLOR_CONSTANTS.DENIM}>
                Goodbye Average SMM Tools.
                <br />
                👋
                <H1
                  as="span"
                  fontSize="4xl"
                  fontWeight="bold"
                  color="primary"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Vista Social
                </H1>: Feature Heavy, Future Ready.
              </H1>
            </Flex>
            <Flex mt="m" pt="s" alignItems="center" flexDirection="column" maxWidth="720px" zIndex="1">
              <Text color="secondaryText" fontSize="l">
                From bulk scheduling, boosting and highly customized reporting to endless integrations and employee
                advocacy; we take social media management to a whole new level. Seriously.
              </Text>
            </Flex>
            <Flex mt="xl" alignItems="center" flexDirection="column">
              <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                <StyledButtonGradient as="a" href={PROMO_LINK} target="_blank">
                  <Text p="m" fontSize="s">
                    Try Vista Social FREE
                  </Text>
                </StyledButtonGradient>
              </StyledGetStartedButtonWrapper>
              <Text color="secondaryText" mt="m" pt="s" pb="m">
                Start FREE, then 99% off your first month for{' '}
                <Text as="span" color="secondaryText" fontWeight="medium">
                  Reelies Award Show attendees
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Container>

        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pb={{ mobile: 'l', tablet: 'xl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 's', desktop: 's' }}>
            <G2FeaturesComponent />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="968px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Not sure yet? Want to see{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Vista Social{' '}
              </H1>
              in action before you sign up? We got you!
            </H3>
          </Flex>

          <Flex mt="l" pt="s" alignItems="center" flexDirection="column">
            <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
              <StyledButtonGradient as="a" href={DEMO_LINK} target="_blank">
                <Text p="m" fontSize="s">
                  Book a FREE Strategy Session
                </Text>
              </StyledButtonGradient>
            </StyledGetStartedButtonWrapper>
          </Flex>

          <Flex mt="m" pt="s" justifyContent="center" flexDirection="row" alignItems="center" mx="auto">
            <Text fontSize="l" color="secondaryText" textAlign="center">
              Learn how Vista Social can help your business save time, stay ahead and collaborate faster ⚡
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
            <Flex flexDirection="column" alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
              <LocalImage src={logoBig} maxWidth="147px" alt="Vista Social" />

              <Flex mt="l" flexDirection="column">
                <H3
                  fontSize="3xl"
                  fontWeight="bold"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Stop Scrolling, Start Winning.
                </H3>
                <H3 fontSize="3xl" fontWeight="bold" color="primary" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Seriously, Don’t Think Twice.
                </H3>
              </Flex>

              <Flex flexDirection="column" mt="m">
                <Text fontSize="l" color="secondaryText" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Get you an SMM tool so slick, you'll wonder how you ever lived without us.
                </Text>
                <br />
                <Text fontSize="l" color="secondaryText" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  We are built to deliver unparalleled value to marketing agencies, freelancers and social media
                  managers. Plus, Vista's awesome team offers top-notch training, strategy advice, and fantastic live
                  support, all along the way!
                </Text>
              </Flex>

              <Flex mt="l" borderRadius={radius.m} bg={transparentize(0.9, colors.primary)} p="xs">
                <Text
                  color="primary"
                  px="xxs"
                  fontSize="l"
                  fontWeight="bold"
                  textAlign={{ mobile: 'center', tablet: 'left' }}
                >
                  Sign up for our free trial today, and enjoy your first month for 99% off, on us!
                </Text>
              </Flex>

              <Flex mt="l" pt="s" alignItems="flex-start" flexDirection="column">
                <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledButtonGradient as="a" href={PROMO_LINK} target="_blank">
                    <Text p="m" fontSize="s">
                      Special Offer Awaits - Claim Now
                    </Text>
                  </StyledButtonGradient>
                </StyledGetStartedButtonWrapper>
              </Flex>

              <Flex mt="m">
                <Text as="span" color="secondaryText" fontSize="m">
                  Not sure yet?
                </Text>
                &nbsp;
                <StyledDemoLink as="a" href={DEMO_LINK} target="_blank" fontSize="m" color="primary" fontWeight="bold">
                  Book a Demo
                </StyledDemoLink>
              </Flex>
            </Flex>

            <StyledFeaturesWrapper>
              <Grid gridTemplateColumns="1fr" gridGap="l" p="xs">
                {FEATURE_ITEMS.map(({ title, description, icon }) => {
                  return (
                    <Flex key={title} flexDirection="column">
                      <Flex alignItems="center">
                        <LocalImage src={icon} alt={title} width="44px" height="44px" />
                        <Text ml="s" color="white" fontWeight="bold" fontSize="l" textAlign="left">
                          {title}
                        </Text>
                      </Flex>
                      <Text mt="m" color="white" fontSize="m" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  )
                })}
              </Grid>
            </StyledFeaturesWrapper>
          </Grid>

          <Flex
            position="relative"
            mt={{ mobile: 'l', tablet: 'xxl' }}
            justifyContent="center"
            borderRadius={radius.xxxl}
          >
            <BgImgBackground src={bgNotSure} width="100%" objectFit="cover" height="100%" borderRadius={radius.xxxl} />

            <Flex justifyContent="center" p="l">
              <Flex justifyContent="center" p="s" zIndex="1">
                <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
                  Still not sure? No worries! <br />
                  Download your free gift below to unlock a{' '}
                  <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                    Vista power{' '}
                  </H3>
                  !
                </H3>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper position="relative">
        <BgImgBackground src={bgInstagramHooks} width="100%" objectFit="cover" height="100%" />

        <Container
          pl={{ mobile: 'l', desktop: 'l' }}
          pr={{ mobile: 'l', desktop: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
          zIndex="1"
        >
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }} gridGap="l">
            <Flex flexDirection="column">
              <Flex bg={COLOR_CONSTANTS.WHITE} borderRadius={radius.m} width="fit-content">
                <Text color="primary" fontSize="xxl" fontWeight="bold">
                  Scroll Stopping:
                </Text>
              </Flex>

              <Text mt="s" color="white" fontSize="3xl" fontWeight="bold" textAlign="left">
                31 Instagram Hooks for Explosive Engagement in 2024
              </Text>

              <Flex mt="l" flexDirection="column" opacity="0.8">
                <Text fontSize="m" textAlign="left" color="white">
                  Innovation is in our DNA, and we're here to keep you ahead of the curve.
                  <br />
                  <br />
                  Grab our free cheat sheet for a month of Instagram growth hooks! Craft content that turns heads and
                  sparks conversations, because conversations = conversions.
                  <br />
                  <br />
                  Whether you're a social media pro, a freelancer, or an agency, our innovative hooks will transform
                  casual scrollers into loyal fans. Grow your audience like never before. 🚀
                  <br />
                  <br />
                  You can’t plan virality, but you can plan mentality. Get your copy now and watch your Instagram
                  explode with activity!
                </Text>
              </Flex>

              <StyledFormWrapper mt="l" flexDirection="column" id="hubspot-form"></StyledFormWrapper>
            </Flex>

            <Flex alignItems="center" display={{ mobile: 'none', desktop: 'flex' }}>
              <LocalImage
                src={imageInstagramHooks}
                alt="31 Instagram Hooks for Explosive Engagement in 2024"
                maxWidth="444px"
                maxHeight="597px"
              />
            </Flex>
          </Grid>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default ReeliesLanding
